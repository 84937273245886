body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arial, sans-serif';
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  transition: margin-left 0.5s ease-in-out;
}

.form-button {
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 20px; /* Adjust margin as needed */
  border: none;
  transition: background 0.3s ease;
}

.form-button:hover {
  background: linear-gradient(45deg, #FF8C00, #FFC700); /* Darker Orange to Yellow gradient */
}

.success-message {
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
}

.otp-field {
  width: 50px;
  height: 50px;
  margin: 5px;
  text-align: center;
  border: 1px solid;
  border-radius: 10px;
  border-image: linear-gradient(45deg, #FFA500, #FFD700) 1;
  font-size: 1.5em;
}

.otp-field input {
  text-align: center;
  font-size: 1.5em;
  padding: 0;
}

.error-message {
  margin-top: 8px;
  color: red;
  text-align: center;
}

.timer {
  margin-top: 10px;
  color: grey;
  font-size: 0.9em;
}

.resend-button {
  text-transform: none;
  margin-left: 5px;
  color: grey;
  cursor: not-allowed;
}

.resend-button.active {
  color: #FFA500;
  cursor: pointer;
}

/* Add this to your CSS file (e.g., MessageForm.css or PhoneVerification.css) */
.password-field {
  transition: opacity 1s ease-in-out;
}

.form-section-title {
  font-family: 'Playpen Sans', Arial, sans-serif !important; /* Apply the handwritten font */
  font-weight: 400 !important; /* Make the font bolder */
  font-size: 1.2rem !important; /* Increase font size */
  color: #8b6f4b; /* Set the desired color */
  margin-top: 20px; /* Add some margin for spacing */
}

/* Ensure this rule is added */
.form-control {
  font-family: 'Playpen Sans', Arial, sans-serif !important;
  font-size: 18px !important; /* Set the font size to 18px */
}

/* Ensure this rule is added for the phone input */
.react-tel-input .form-control:focus {
  outline: none !important; /* Remove the default outline */
  border: 2px solid #FFA500 !important; /* Match the orange border */
  box-shadow: 0 0 1px rgba(255, 165, 0, 0.5) !important; /* Match the orange shadow */
}

.phone-input-container:focus-within .special-label {
  color: #FFA500; /* Match the orange color */
  background-color: rgb(250, 243, 243) !important; 

}
.phone-input-container .special-label {
  background-color: rgb(250, 243, 243) !important; 

}


.react-tel-input .special-label { 
  font-family: 'Playpen Sans', sans-serif !important;
  font-size: 12px !important; /* Adjust the font size as needed */
  display: block !important;
  margin-left: -15px !important; /* Adjust the value as needed to shift left */ 
  margin-top: -3px !important;
}

/* Ensure this rule is added */
.phone-input-container {
  margin-bottom: 10px !important;
  position: relative;
}

.react-tel-input .form-control {
  background-color: transparent !important; /* Make the input background transparent */
  height: 50px !important; /* Set the desired height */
  margin-bottom: auto;

}

/* Adjust the flag dropdown to the right */
.react-tel-input .flag-dropdown {
  background-color: transparent !important; /* Make the flag dropdown background transparent */
  border: none !important; /* Remove the border */
  box-shadow: none !important; /* Remove the box shadow */
  margin-left: 10px !important; /* Adjust this value to shift the dropdown to the right */
}

.react-tel-input .selected-flag {
  border: none !important; /* Remove the border */
  background-color: transparent !important; /* Make the selected flag background transparent */
  margin-left: -5px !important; /* Adjust this value to position the selected flag correctly */
}

.react-tel-input .selected-flag .flag {
  margin-right: 10px; /* Adjust margin to position the flag correctly */
}


.react-tel-input .country-list {
  background-color: white !important; /* Ensure the dropdown list has a white background */
  border: 1px solid #ccc; /* Add a border to the dropdown list */
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1); /* Add a subtle shadow to the dropdown */
}

.react-tel-input .country-list .country {
  padding: 10px; /* Adjust padding for country list items */
}

.react-tel-input .country-list .country .dial-code {
  margin-left: 10px; /* Adjust margin for dial code */
}

/* Customize the dropdown arrow */
.react-tel-input .selected-flag .arrow {
  border-color: #000000 transparent transparent !important; /* Change the color of the arrow */
  border-width: 5px !important; /* Make the arrow smaller */
  border-style: solid !important;
  margin-left: 2px; /* Adjust the margin to position the arrow correctly */
}

.react-tel-input .selected-flag .arrow:after {
  border-color: #000000 transparent transparent !important; /* Change the color of the arrow */
  content: ''; /* Remove the default arrow */
  display: none;
}

.react-tel-input .selected-flag.open .arrow {
  border-color: transparent transparent #000000 !important; /* Change the color of the arrow when dropdown is open */
  
}

/* Adjust the appearance of the single input to look like separate OTP boxes */
.otp-container .MuiInputBase-input {
  letter-spacing: 1em;  /* Adjust this to create enough space between each digit */
}

.otp-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 30px;  /* Add spacing below the OTP boxes */
}

.otp-boxes {
  display: flex;
  justify-content: center;
}

.otp-box {
  width: 50px;  /* Ensure width and height are equal */
  height: 50px;  /* Match height to width for a circular shape */
  margin: 0 10px; /* Increase margin between boxes */
  text-align: center;
  position: relative;  /* Position for the pseudo-element */
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;  /* Ensure background color is set */
  border-radius: 50%;  /* Ensure corners are fully rounded */
  box-sizing: border-box;  /* Include padding and border in the element's total width and height */
}

.otp-box::before {
  content: '';
  position: absolute;
  top: -2px;  /* Adjust to control the border thickness */
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #FFA500, #FFD700);  /* Gradient for the border */
  border-radius: 50%;  /* Make sure the gradient follows the circle shape */
  z-index: -1;  /* Ensure the border is behind the content */
}

