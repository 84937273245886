.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5; /* Background color or gradient */
}

.checkmark-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: url(#gradient); /* Use gradient for stroke */
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px url(#gradient);
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: url(#gradient); /* Use gradient for stroke */
  fill: none;
  animation: stroke .6s cubic-bezier(.65,.05,.36,1) forwards;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s cubic-bezier(.65,.05,.36,1) .6s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px url(#gradient);
  }
}

.celebration-circle {
  fill: url(#gradient);
  opacity: 0;
  animation: celebration-animation 1s ease-in-out forwards;
}

@keyframes celebration-animation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
