body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Playpen Sans, Arial, sans-serif';
  font-weight: 400; /* Normal weight */
}

.message-form-container {
  margin: 20px auto;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Add transition */
  transform: translateY(20px);
  opacity: 0;
}

.message-form-container.entered {
  transform: translateY(0); /* Move to original position */
  opacity: 1; /* Fully opaque */
}

.progress-bar-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  width: 90%;
  margin: 0 auto 10px auto;
}

.progress-bar-step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-weight: bold;
  padding: 5px;
  background: #e0e0e0;
  color: #a44d4d;
  flex-direction: column;
  font-size: 12px;
  line-height:1; /* Ensure tighter line spacing */
  text-align: center; /* Center text within the circle */
}

.progress-bar-step .step-label {
  font-size: 10px; /* Smaller font for better fit */
  margin-top: -3px;
  text-align: center;
  line-height:0.35cm; /* Keep text lines close together */
}




.progress-bar-step.active {
  background: linear-gradient(45deg, #FFA500, #FFD700);
  color: #fff;
}

.progress-bar-line {
  flex-grow: 1;
  height: 5px;
  background: #e0e0e0;
  margin: 0 10px;
}

.progress-bar-line.active {
  background: linear-gradient(45deg, #FFA500, #FFD700);
}

.progress-bar-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto 20px auto;
}

.progress-bar-label {
  font-size: 13px !important;
  font-weight: bold;
  color: #8b6f4b;
}

@media (min-width: 768px) {
  .progress-bar-label  {
    font-size: 14px !important;
  }
}

.progress-bar-label.active {
  color: #FFA500;
}

.loading-spinner {
  margin-bottom: 20px;
}

.feedback-container {
  margin-bottom: 20px;
}

.feedback {
  font-family: 'Arial, sans-serif';
  margin-bottom: 10px;
}

.feedback.positive {
  color: green;
}

.feedback.negative {
  color: red;
}

.button-container {
  display: flex;
  justify-content: center;
}

.form-button {
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 20px; /* Adjust margin as needed */
  border: none;
  transition: background 0.3s ease;
}

.form-button:hover {
  background: linear-gradient(45deg, #FF8C00, #FFC700); /* Darker Orange to Yellow gradient */
}


.sent-message {
  margin-top: 20px;
  font-size: 18px;
  color: #4caf50;
}

.header-container {
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  z-index: 1000;
}

.header-logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-separator {
  height: 2px;
  width: 100%;
  background: linear-gradient(45deg, #FFA500, #FFD700);
}


.intl-tel-input {
  display: flex;
  align-items: center;
}

.intl-tel-input .selected-flag {
  margin-right: 5px;
}

.input-field {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  transition: margin-left 0.5s ease-in-out;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form */
}

.form-content {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-message {
  margin-top: 8px;
  text-align: center;
}

/* Add this CSS to MessageForm.css */

.char-count-container {
  display: flex;
  justify-content: flex-end; /* Align text to the right */
  width: 100%;
}

.char-count {
  text-align: right;
  margin-top: 8px;
  margin-right: 8px;
  color: #757575;
}

.form-section-title {
  font-family: 'Playpen Sans', Arial, sans-serif !important; /* Apply the handwritten font */
  font-weight: 400 !important; /* Make the font bolder */
  font-size: 0.8rem !important; /* Increase font size */
  color: #000000; /* Set the desired color */
  margin-top: 20px; /* Add some margin for spacing */
}

.sent-message {
  margin-top: 20px;
  font-size: 18px;
  color: #8b6f4b;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.message-input {
  color: #8b6f4b;
}

.countdown-timer-rect {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #FFA500, #FFD700);
  border-radius: 25px; /* Smooth and round edges */
  padding: 20px 40px; /* Increase padding to adjust height */
  color: white;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.countdown-number, .countdown-colon {
  margin: 0 5px; /* Add a bit of horizontal margin for spacing */
  font-size: 2rem; /* Increase font size */
  line-height: 1;
}

.countdown-label {
  margin: 0 5px; /* Add a bit of horizontal margin for spacing */
  font-size: 1.2rem; /* Increase font size */
  line-height: 1;
  color: white;
}

.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #FFA500; /* Adjust color to fit the theme */
}

.positivity-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px; /* Add margin for spacing */
  color: #FFA500; /* Adjust color to fit the theme */
}

.remind-me-label {
  color: rgba(0, 0, 0, 0.6); /* Adjust to the same grey level as the dialog message */
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px 8px; /* Add padding for better alignment */
}

.remind-me-container {
  margin-left: 0; /* Align checkbox and label to the left */
  display: flex;
  align-items: center;
  flex-grow: 1; /* Ensure it takes up available space */
  padding-left: 24px; /* Align with the left padding of the dialog content */
}

