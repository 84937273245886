body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Playpen Sans', Arial, sans-serif;
  font-weight: 400; /* Normal weight */
}

.background-container {
  background-image: url('./assets/website_background-min.png'); /* Make sure the path to your image is correct */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .background-container {
    background-image: url('./assets/mobile_background.png'); /* Mobile background image */
  }
}

.banner-container {
  position: relative;
  text-align: center;
  color: white;
  background-color: transparent;
  padding: 20px;
  margin-top: 200px; /* To avoid overlap with fixed header */
}

.button-group {
  display: flex;
  gap: 20px; /* Adds space between the buttons */
  justify-content: center; /* Center the buttons horizontally */
}

.form-button {
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  font-weight: bold;
  transition: background 0.3s ease;
  width: 150px; /* Adjust the width as needed */
  font-size: 1.2rem; /* Adjust font size if necessary */
  text-align: center; /* Ensure the text is centered */
  cursor: pointer;
}

.form-button:hover {
  background: linear-gradient(45deg, #FF8C00, #FFC700); /* Darker Orange to Yellow gradient */
}

.message-form-container {
  margin: 20px auto;
  padding: 40px;
  background-color: rgb(250, 243, 243); /* Set background to white with some transparency */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  text-align: center;
  background-image: none; /* Ensure no background image */
  position: relative;
  margin-top: -20%; /* Adjust according to your layout needs */
}

/* Adjustments for medium and small screens */
@media (max-width: 1024px) {
  .message-form-container {
    margin-top: -60%; /* Adjust margin for medium screens */
  }
}

@media (max-width: 768px) {
  .message-form-container {
    margin-top: -60%; /* Adjust margin for small screens */
  }
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('out2.png'); /* Ensure the path to your image is correct */
  background-size: cover; /* Ensure the image fills the screen */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-position: center; /* Center the image */
  z-index: 9999;
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

.splash-screen.fade-out {
  opacity: 0;
  pointer-events: none;
}

.progress-bar-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.progress-bar-step {
  font-weight: bold;
  padding: 10px;
  border-radius: 40px;
  background: #dcdcdc;
  color: #ffffff;;
}

.progress-bar-step.active {
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
  color: #fff;
}

.quote {
  font-family: 'Playpen Sans';
  font-weight: 400;
  color: #849e95;
  margin-bottom: 20px;
  transition: opacity 0.5s ease-in-out;
  text-align: center;
  background-color: rgb(250, 243, 243); /* Semi-transparent background */
  padding: 10px;
  border-radius: 10px;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.loading-spinner {
  margin-bottom: 20px;
}

.feedback-container {
  margin-bottom: 20px;
}

.feedback {
  font-family: 'Arial, sans-serif';
  margin-bottom: 10px;
}

.feedback.positive {
  color: green;
}

.feedback.negative {
  color: red;
}

.button-container {
  display: flex;
  justify-content: center;
}

.form-button:disabled {
  background: none;
}

/* Header Button Styles */
.header-button {
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
  color: #fff !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  border-radius: 20px;
  padding: 5px 15px !important;
  border: none;
  transition: background 0.3s ease;
}

.header-button:hover {
  background: linear-gradient(45deg, #FF8C00, #FFC700); /* Darker Orange to Yellow gradient */
}

/* Header Styles */
.header-container {
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  z-index: 1000;
}

.header-logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-separator {
  height: 2px;
  width: 100%;
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
}
