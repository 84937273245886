.quick-guide-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it is above other content */
}

.quick-guide-modal {
  position: relative;
  background-color: white;
  padding: 20px 20px 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.19);
  max-width: 600px;
  text-align: left;
  color: #8b6f4b;
  overflow-y: auto;
  max-height: 100vh;
  min-height: 300px; /* Add a minimum height */
  height: 70vh; /* Set a fixed height */
}
@media (max-width: 768px) {
  .quick-guide-modal {
    max-width: 90vw; /* Make the modal width larger on smaller screens */
    height: 70vh; /* Increase the height to 70% of the viewport height */
    max-height:max-content; /* Allow the modal to grow up to 80% of the viewport height */
    padding: 10px 10px 20px 10px; /* Adjust padding for smaller screens */
    border-radius: 8px; /* Slightly smaller border radius for a sleeker look on mobile */
  }
}


.quick-guide-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  height: auto; /* Adjust as needed */
}

.quick-guide-image-container {
  position: relative;
  width: 100%;
  max-width: 100%; /* Ensure the container takes the full width of the parent */
  margin: 0 auto; /* Center the container */
}

.quick-guide-message {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%; /* Adjust the max-width to make the image smaller */
  height: auto; /* Maintain the aspect ratio */
  position: absolute;
  top: -5%; /* Adjust this value to position the message vertically */
  left:47%; /* Center horizontally */
  transform: translateX(-50%); /* Center the image horizontally */
  opacity: 0; /* Start fully transparent */
  animation: fadeIn 1s ease-in-out forwards; /* Fade-in animation */
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Start fully transparent */
  }
  100% {
    opacity: 1; /* Fully opaque */
  }
}

/* Additional styles for smaller screens */
@media (max-width: 768px) {
  .quick-guide-modal {
    padding: 10px; /* Adjusted padding for smaller screens */
    max-width: 90%; /* Ensure it does not exceed the viewport width */
  }

  .quick-guide-content {
    padding-right: 50px; /* Add padding to avoid text overlay */
  }

  .skip-button {
    top: 10px; /* Adjusted to align with the content */
    right: 10px; /* Adjusted to align with the content */
  }
}

.quick-guide-title {
  font-weight: bold;
  margin-bottom: 15px; /* Adjusted margin */
  color: #8b6f4b; /* Text color */
}

.quick-guide-content {
  margin-top: 15px; /* Adjusted margin to create spacing */
  color: #8b6f4b; /* Text color */
  text-align: center; /* Center align the text */
}

.quick-guide-section-title {
  font-size: 1rem; /* Adjusted font size */
  font-weight: bold;
  margin-top: 10px; /* Adjusted margin */
  margin-bottom: 8px; /* Adjusted margin */
  color: #849e95; /* Updated title color */
  border-bottom: 1px solid #ccc;
}

.quick-guide-text {
  margin-top: 20px !important; /* Adjusted margin to create spacing */
  margin-bottom: 20px !important; /* Adjusted margin to create spacing */
  color: #849e95; /* Text color */
  text-align: center; /* Center align the text */
  opacity: 0;
}

.quick-guide-text-error {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: left; /* Align text to the left */
  line-height: 1.5; /* Add line height for readability */
  padding-left: 20px; /* Add padding for indentation */
  padding-right: 20px; /* Add padding for indentation */
  color: #333333; /* Updated text color to light black */
}


@keyframes slideIn {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.quick-guide-text.slide-in {
  opacity: 0; /* Start fully transparent */
  animation: slideIn 0.5s ease-in-out forwards; /* Slide-in animation */
}

.quick-guide-list {
  list-style-type: disc;
  padding-left: 20px;
  color: #000000; /* Text color */
}

.quick-guide-list li {
  margin-bottom: 10px;
}

.quick-guide-list li::before {
  content: '';
  display: none;
  border-bottom: 1px solid #ccc; /* Add a fine line between paragraphs */
  margin-bottom: 5px;
  margin-top: 5px;
}

.quick-guide-footer {
  display: flex;
  justify-content: space-between; /* Adjusted for multiple buttons */
  align-items: center; /* Align items vertically */
  width: 100%;
}

.quick-guide-button {
  background: linear-gradient(45deg, #FFA500, #FFD700); /* Orange to Yellow gradient */
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 20px;
  padding: 8px 16px; /* Adjusted padding */
  margin-top: 10px; /* Adjusted margin */
  border: none;
  transition: background 0.3s ease;
}

.quick-guide-button.disabled {
  background: #d3d3d3;
  cursor: not-allowed;
}

.indicator-container {
  display: flex;
  justify-content: center;
  flex-grow: 1; /* Allow it to grow and take available space */
}

.indicator-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #d3d3d3;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.indicator-dot.active {
  background-color: #000;
}

.quick-guide-header {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Align items vertically */
  width: 100%;
  box-sizing: border-box;
}

.quick-guide-skip-button {
  background: none;
  border: none;
  color: #8b6f4b;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
  margin-top: 15px; /* Adjust margin to align with footer buttons */
  padding: 0;
  box-sizing: border-box;
}

.quick-guide-skip-button:hover {
  color: #dd940d; /* Orange color on hover */
}

.quick-guide-got-it-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
